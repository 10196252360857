import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import {Grid, Paper, TableContainer, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ParticipantGroupActionButton} from "src/pages/dashboard/super/manage-participant-groups/participant-group-action-button";
import GenericCard from "src/components/generic/GenericCard";
import {isMobile} from "react-device-detect";
import GenericTablePagination from "src/components/generic/GenericTablePagination";

export const ParticipantGroupsTable = props => {
  const {t} = useTranslation();
  const {groups, count, page, handlePageChange, handleEditParticipantGroup} = props;

  return (
    <>
      {isMobile ? (
        <Grid container sx={12} p={2}>
          <Typography variant="h6" component="div" sx={{py: 1}}>
            Participant Groups
          </Typography>
          {groups.map(group => (
            <GenericCard
              key={group.id}
              data={{
                header: {
                  title: " ",
                  props: {
                    action: (
                      <ParticipantGroupActionButton
                        participantGroup={group}
                        handleEdit={handleEditParticipantGroup}
                      />
                    ),
                  },
                },

                unExtendedData: [
                  {
                    title: "ID",
                    description: group.id,
                  },
                  {
                    title: t("manageParticipantGroups.name"),
                    description: group.name,
                  },
                  {
                    title: t("manageParticipantGroups.memberCount"),
                    description: group.member_count,
                  },
                ],
              }}
            />
          ))}
          <Grid container justifyContent="flex-end">
            <GenericTablePagination
              count={count}
              onPageChange={newPage => handlePageChange(newPage)}
              page={page}
              rowsPerPage={10}
            />
          </Grid>
        </Grid>
      ) : (
        <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4, mt: 2}}>
          <Typography variant="h6" component="div" sx={{py: 1}}>
            Participant Groups
          </Typography>
          <Card elevation={0}>
            <TableContainer component={Paper} style={{overflow: "auto"}}>
              <Table sx={{minWidth: 1150}}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>{t("manageParticipantGroups.name")}</TableCell>
                    <TableCell>{t("manageParticipantGroups.memberCount")}</TableCell>
                    <TableCell>{t("tableHeaders.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups.map(group => (
                    <TableRow key={group.id}>
                      <TableCell>{group.id}</TableCell>
                      <TableCell>{group.name}</TableCell>
                      <TableCell>{group.member_count}</TableCell>
                      <TableCell>
                        <ParticipantGroupActionButton
                          participantGroup={group}
                          handleEdit={handleEditParticipantGroup}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <GenericTablePagination
              count={count}
              onPageChange={newPage => handlePageChange(newPage)}
              page={page}
              rowsPerPage={10}
            />
          </Card>
        </Card>
      )}
    </>
  );
};
