import React, {useEffect, useState} from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import {superRewardService} from "src/services/super/project/reward";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {RewardRequest} from "src/entities/project/reward-request";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {datetimeToObject} from "src/utils/datetime";

export const Rewards = ({teamMemberId}) => {
  const {t} = useTranslation();
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [activeTab, setActiveTab] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchRewardRequests = page => {
    setLoading(true);
    let isFlagged = activeTab === "flagged";
    const statusFilter = activeTab === "flagged" ? null : activeTab;

    superRewardService
      .listRewardRequests(page + 1, statusFilter, isFlagged, teamMemberId)
      .then(response => {
        const results = response.results.map(res => new RewardRequest(res));
        setPaymentRequests(results);
        setPage(page);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRewardRequests(0);
  }, [teamMemberId, activeTab]);

  const renderTableBody = () => {
    if (loading) {
      return Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell colSpan={4} align="center">
            <CircularProgress size={24} />
          </TableCell>
        </TableRow>
      ));
    }

    return paymentRequests.map(paymentRequest => (
      <TableRow key={paymentRequest.id} hover style={{cursor: "pointer"}}>
        <TableCell>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <FiberManualRecordIcon
              fontSize="small"
              // Duruma göre renk ayarı
              color={
                paymentRequest.status === "approved"
                  ? "success"
                  : paymentRequest.status === "pending"
                    ? "warning"
                    : "error" // rejected için kırmızı
              }
              sx={{marginRight: 1}}
            />
            {paymentRequest.status}
          </Box>
        </TableCell>
        <TableCell>{paymentRequest.amount + (paymentRequest.gift_amount ?? 0)}</TableCell>
        <TableCell>{paymentRequest.type}</TableCell>
        <TableCell>{datetimeToObject(paymentRequest.created_at)}</TableCell>
      </TableRow>
    ));
  };
  return (
    <Typography
      variant="h5"
      sx={{marginBottom: 4, marginTop: 4, textAlign: "left", fontWeight: "bold"}}
    >
      {t("participantPaymentRequests.participantPaymentRequests")}
      <Box sx={{py: 4}}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("participantPaymentRequests.requestedStatus")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedAmount")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedMarket")}</TableCell>
                <TableCell>{t("participantPaymentRequests.createdTime")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Typography>
  );
};
