import axiosInstance from "src/services/axiosInstance";

class TeamService {
  async createTeam(payload) {
    return await axiosInstance
      .post(`/teams`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async inviteTeamMember(teamId, payload) {
    return await axiosInstance
      .post(`/teams/${teamId}/members/bulk-invite`, payload)
      .then(invitation => {
        return invitation?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listTeams() {
    return await axiosInstance
      .get(`/teams`)
      .then(teams => {
        return teams?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const teamService = new TeamService();
