export const trTranslations = {
  mySurveys: "Anketlerim",
  newSurvey: "Yeni Anket",
  AddRandomizer: "Rastgeleleştir",
  question: "Soru",
  Turkish: "Türkçe",
  English: "İngilizce",
  mainPage: {
    howToUse: "Platformumuz nasıl kullanılıyor?",
    inNumbers: "Sayılarla",
    register: "Kayıt Ol",
    prepareSurveys: "Anketleri Hazırla",
    reachOutParticipants: "Katılımcılara Ulaş",
    registerDescr: "İlk olarak platformumuza kayıt olun ve platforma erişim sağlayın.",
    prepareSurveysDescr: "Platforma giriş yapın ve ilginizi çeken anketi doldurun.",
    reachOutDescr:
      "Anketi yayınlayın ve katılımcılara ulaşarak hızlıca doldurulmasını sağlayın.",
    participantPool: "Katılımcı Havuzu",
    surveyDiversity: "Anket Çeşitliliği",
    rewardDiversity: "Ödül Çeşitliliği",
    support: "Destek",
    participantPoolDescr: "Anketlerinize assign edebileceğimiz çok sayıda kişi",
    surveyDiversityDescr: "Çok sayıda taslak anket",
    rewardDiversityDescr:
      "Kazanabileceğiniz hediye çekleri, indirimler, nakit ödüller ve daha fazlası",
    supportDescr: "Anketleriniz için sürekli destek",
    FAQ: "SSS",
  },

  newSurveys: {
    createNewSurveyTitle: "Yeni anket oluştur",
    createNewSurveyDescription: "Sıfırdan veya bir excel dosyasından başla",
    newSurveyFromScratch: "Sıfırdan yeni anket oluştur",
    newSurveyFromExcel: "Excelden yeni anket oluştur",
    newSurveyFromScratchDescription: "Sıfırdan başla",
    uploadFromExcel: "Excelden Yükle",
    selectFromPopularTemplates: "En popüler şablonları seç",
    demographicPoll: "Demografik Anket",
    customerFeedback: "Müşteri Geri Bildirimi",
    customizeDraft: "Taslağı Özelleştir",
    employeeFeedback: "Çalışan Geri Bildirimi",
    startSurvey: "Başla",
  },

  selectTheSurveyYouWantToDistribute: "Dağıtmak İstediğiniz Anketi Seçin",
  selectParticipantDemographics: "Katılımcı Demografisini Seçin",
  sendToAnAgentToGetAQuote: "Kayıt etmek için göndere tıklayın",
  findParticipants: {
    selectTheSurvey: "Anket Seç",
    numberofParticipants: "Katılımcı Sayısı",
    pleaseSelectGender: "Lütfen ulaşmak istediğiniz cinsiyeti seçin",
    pleaseSelectAge: "Lütfen ulaşmak istediğiniz yaş aralığını seçin",
    selectGender: "Cinsiyet Seç",
    selectAgeGroups: "Yaş Grubu Seç",
    projectDetails: "Proje Detayları",
    weCanFindParticipants:
      "En iyi katılımcıları bulabilmemiz için bize projeniz hakkında daha fazla bilgi verin",
    pleaseSendGender: "Lütfen eklemek istediğiniz cinsiyeti seçin",
    pleaseSendAge: "Lütfen eklemek istediğiniz yaş aralığını seçin",
  },
  findparticipants: "Katılımcıları Bul",

  findParticipantsRequest: {
    findParticipantsRequest: "Katılımcı Taleplerini Yönet",

    requestedStatus: "İstek Durumu",
    requestedDate: "İstek Tarihi",
    surveyName: "Anket Adı",
    surveyDescription: "Anket Açıklaması",
    requestedGender: "İstenilen Cinsiyet",
    requestedAge: "İstenilen Yaş",
    numberOfParticipants: "Katılımcı Sayısı",
    participantRequestManagment: "Katılımcı Talep Yönetimi",
    sendParticipant: "Katılımcı Ekle",
    takeOutParticipant: "Katılımcı Çıkar",
  },
  sendParticipant: {
    selectedRequest: "Seçilen Talep: ",
    surveyTopic: "Anket Konusu",
    surveyDuration: "Anket Süresi",
    surveyLink: "Anket Linki",
    targetNumberOfParticipants: "Hedeflenen Katılımcı Sayısı",
    assignBatches: "Grupları Ata",
    topic: "Konu",
    status: "Durum",
    assignedParticipantCount: "Atanan Katılımcı Sayısı",
    durationMinute: "Süre (dakika)",
    participantGender: "Katılımcı Cinsiyeti",
    participantAge: "Katılımcı Yaşı",
    rewardDefinition: "Ödül Tanımı",
    paymentStatus: "Ödeme Durumu",
    sendSurvey: "Anketi Gönder",
  },

  takeOutParticipant: {
    selectedGender: "Seçilen Cinsiyet",
    selectedAge: "Seçilen Yaş",
    takeOutParticipant: "Anketten Çıkar",
  },

  surveyStatus: {
    title: "Anket Durumu",
    completedTotal: "Tamamlayan ",
    completedCount: "Tamamlayan Toplam: ",
    completedAssignedGroup: " Tamamlayan Atanan Grup:",
    createGroup: "Grup Oluştur",
    assignedGroup: "Atanan Grup",
    assignedGroupCount: "Atanan Grup: ",
    assignedGroupGender: "Atanan Grup Cinsiyet: ",
    assignedGroupAge: "Atanan Grup Yaş: ",
    requestDetails: "Talep Detayları",
    requestCount: "Talep Sayi: ",
    requestGender: "Talep Grup Cinsiyet: ",
    requestAge: "Talep Grup Yas: ",
  },

  events: {
    completedEvents: "Tamamlanan Etkinlikler",
    surveyName: "Anket Adı",
    rewardAmount: "Ödül Miktarı",
    rewardStatus: "Ödül Durumu",
    defined: "Tanımlandı",
    undefined: "Tanımlanmadı",
  },

  submittedRequests: {
    submittedRequests: "Gönderilen İstekler",
    survey: "Anket",
    participants: "Katılımcılar",
    gender: "CİNSİYET",
    age: "Yaş",
    projectDetails: "Proje Detayları",
    status: "Durum",
    noSubmittedRequestsYet: "Henüz gönderilen istek yok.",
    submit: "Gönder",
  },

  settingcategory: "Ayarlar",
  none: "Seçilmedi",
  male: "Erkek",
  female: "Kadın",
  other: "Diğer",
  all: "Hepsi",
  editInfo: {
    editInfo: "Bilgileri Düzenle",
    mail: "Mail",
    phoneNumber: "Telefon No (0532 000 00 00)",
    gender: "Cinsiyet",
    birthday: "Doğum Günü",
    country: "Ülke",
    province: "Şehir",
    editAccountInformation: "Hesap Bilgilerini Düzenle",
    saveAccountInformation: "Hesap Bilgilerini Kaydet",
    changePassword: "Şifre Değiştir",
    cancel: "Geri",
    delete: "Sil",
    changeCommunicationPreferences: "İletişim Tercihlerini Değiştir",
    deleteAccountConfirmation: "Hesabınızı silmek istediğinizden emin misiniz?",
    deleteAccountConfirmationDescription:
      "Hesabınızın silinmesi geri alınamaz. Tüm verileriniz kalıcı olarak silinecek. Devam etmek istediğinizden emin misiniz?",
    deleteAccount: "Hesabı Sil",
    changePasswordConfirmation: "Şifrenizi değiştirmek istediğinizden emin misiniz?",
    changePasswordConfirmationDescription:
      "Şifrenizi değiştirmek tekrar giriş yapmanızı gerektirecektir. Devam etmek istediğinizden emin misiniz?",
  },

  excellToSurvey: "Excel'den Ankete",

  tableHeaders: {
    title: "Başlık",
    isPublished: "AKTİFLİK Durumu",
    questions: "Sorular",
    responses: "Yanıtlar",
    completion: "Tamamlama Oranı",
    created: "Oluşturulma TARİHİ",
    updated: "Güncellenme TARİHİ",
    actions: "Eylemler",
  },
  prev: "Önceki Buton",
  next: "Sonraki Buton",
  noDataFound: "Veri bulunamadı",
  publish: "Yayınla",
  published: "Yayında",
  draft: "Taslak",
  settings: {
    surveyTitle: "Anket Başlığı",
    open: "Açık",
    close: "Kapalı",
  },
  block: {
    enterBlockName: "Blok Adı Girin",
  },
  buttons: {
    downloadDraftExcel: "Taslak Excel Dosyasını İndir",
    UploadDraftExcel: "Düzenlenmiş Excel Dosyası",
    yourDraftExcel: "Excel Dosyanız",
  },
  step: "Adım",
  hasBeenUploaded: "Yüklendi",
  downloadExcelFormat: "Excel Formatını İndir",
  importExcelDirective:
    " Excel dosyasındaki soruları ankete dönüştürün. Lütfen anket adınızı tanımlayın ve düzenlenmiş excel dosyanızı yükleyin.",
  importFromExcelFile: "Excel Dosyasından İçe Aktar",
  importExcelError: "Atlanan satır(lar)da geçersiz sorular var. Dizin: ",

  writeToExcelFormat: "Sorularınızı excel dosyasına yazın veya yapıştırın",
  uploadQuestionsFromExcel: "Anket sorularınızı Excel'den doğrudan platforma yükleyin",
  downloadDraftExcelDirective:
    "Kendi sorularınızı kopyalayıp yapıştırmak için taslak excel'i indirebilirsiniz. Soru ve blok ayarlarını bu excelden yönetebilirsiniz, örneğin gerekli, randomizasyon, çoklu cevap izni gibi.",
  defineEditedExcelFile:
    "Lütfen anket adınızı tanımlayın ve düzenlenmiş excel dosyanızı yükleyin.",
  importExcelButtonLabel: "Excel Dosyasından İçe Aktar",
  importExcelDialogTitle: "Excel Dosyası İçe Aktar",
  importExcelNameLabel: "İsim",
  importFileButtonLabel: "Dosyayı İçe Aktar",
  createSurveyButtonLabel: "Oluştur",
  createSurveyDialogTitle: "Anket Oluştur",
  nameInputLabel: "Ad",
  createButtonLabel: "Oluştur",
  cancelButtonLabel: "İptal",
  copyLink: "Bağlantıyı Kopyala",
  copySurvey: "Anketi Kopyala",
  delete: "Sil",
  deleteSurveyTitle: "Anketi Sil",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} anketi kalıcı olarak silinecek. Emin misiniz?",

  cancel: "İptal",
  apply: "Uygula",

  sections: {
    survey: "Anket",
    preview: "Önizleme",
    distributions: "Dağıtımlar",
    results: "Sonuçlar",
    analyticsDashboard: "Analitik Ekranı",
  },
  theme: {
    defaultTheme: "Varsayılan Tema",
    brandDarkTheme: "Koyu Marka Teması",
    brandLightTheme: "Açık Marka Teması",
    grayTheme: "Gri Tema",
    darkUnicaTheme: "Koyu Unica Teması",
    sandSignikaTheme: "Kum Signika Teması",
    oceanBreezeTheme: "Okyanus Esintisi Teması",
    sunsetGlowTheme: "Gün Batımı Parıltısı Teması",
  },
  basicSettings: {
    title: "Temel Ayarlar",
    language: "Dil",
    languageDescription:
      "Anketinizi yazdığınız dili seçmenizi öneririz. Yanıt veri de bu dilde görüntülenecektir.",
    previousButton: "Önceki Buton",
    previousButtonDescription: "Önceki butonu etkinleştir/devre dışı bırak.",
    ipBlock: "IP Engelleme",
    ipBlockDescription: "Aynı IP'den birden fazla yanıtı engelle.",
    shareLink: "Anketi Paylaş Linki Ekle",
    shareLinkDescription: "Anketin sonunda paylaşılabilir bir link gösterilsin.",
  },
  writeSomething: "Bir şeyler yazın",
  unavailableSurveyMessage: "Kullanılamayan Anket Mesajı",
  buttonLabels: {
    update: "Güncelle",
    reset: "Sıfırla",
  },
  blockSettings: {
    block: "Blok",
    editBlock: "Blok Düzenle",
    displayLogicWarning:
      "Aynı blok içinde görüntüleme mantığı ve rastgeleleştirme kullanmaya çalışılıyor. Bu kombinasyon beklenildiği gibi çalışmayabilir.",
    randomizeQuestions: "Soruları Rastgeleleştir",
    addPageBreak: "Tüm Sorulara Sayfa Sonu Ekle",
    makeAllRequired: "Tüm Soruları Zorunlu Yap",
    allowMultipleAnswers: "Birden Çok Cevaba İzin Ver",
    otherButton: "Diğer Buton",
    carryChoices: "Seçenekleri Taşı",
    skipLogic: "Soru Atlatma",
    selectAnOption: "Bir Opsiyon Seç",
    selectOperand: "Operand Seçin",

    noMultipleQuestions: "Çok Sayıda Soru Yok",
    choices: "Seçenekler",

    selectAQuestion: "Bir Soru Seç",
    skipTo: "Atla",
    endOfSurvey: "Anket Sonu",
    endOfBlock: "Blok Sonu",
  },

  flow: {
    flowList: "Akış Listesi",
    whatDoYouWantToAdd: "Ne Eklemek İstiyorsun?",
    moveButton: "Taşı",
  },

  results: {
    users: "Kullanıcılar",
    completed: "Tamamlandı",
    startDate: "BAŞLAMA TARİHİ",
    endDate: "BİTİŞ TARİHİ",
    duration: "Süre",
    status: "Durum",
  },

  chartType: {
    columnChart: "Sütun Grafik",
    barChart: "Çubuk Grafik",
    pieChart: "Pasta Grafik",
    lineChart: "Çizgi Grafik",
    areaChart: "Alan Grafik",
    scatterChart: "Dağılım Grafik",
    splineChart: "Spline Grafik",
    bubbleChart: "Balon Grafik",
    polarChart: "Kutup Grafik",
    radarChart: "Radar Grafik",
  },
  editor: {
    endPageLabel: "Son Sayfa",
    createBlockTooltip: "Soru Bloğu Oluştur",
    editBlockTooltip: "Soru Bloğunu Düzenle",
    deleteBlockTooltip: "Soru Bloğunu Sil",
    blockDeletionConfirmation: "bloğu kalıcı olarak silinecek. Emin misin?",
  },
  analyticsDashboard: {
    firstQuestionForChart: "Grafik İçin İlk Soruyu Seçin",
    addSecondQuestionforChart: "Grafik için İkinci Soruyu Ekleyin",
    selectSecondQuestionForchart: "Grafik için İkinci Soruyu Seçin",
    selectAQuestion: "Bir soru seçin",
    chartTitle: "Grafik Başlığı",
    unitType: "Birim Türü",
    chartType: "Grafik Türü",
    highchartsWithMUI: "MUI ile Highcharts",
  },

  downloadResults: "Sonuçları İndir",
  downloading: "İndiriliyor",
  resetSurvey: "Anketi Sıfırla",
  questionLabel: "Soru Etiketi",
  duplicate: "Çoğalt",
  remove: "Sil",
  shuffleQuestion: "Seçenekleri Karıştır",
  deleteQuestion: "Soruyu Sil",
  ignoreRequire: "Zorunlu Cevaplamayı Yoksay",
  ignorePreviewData: "Önizleme Verilerini Yoksay",
  addOption: "Seçenek Ekle",

  label: "Varsayılan Soru Etiketi",
  choice1: "Seçenek 1",
  choice2: "Seçenek 2",
  choice3: "Seçenek 3",
  defaultQuestion: "Varsayılan Soru Başlığı",
  save: "Kaydet",
  reset: "Sıfırla",
  isEqual: "Eşit",
  isNotEqual: "Eşit değil",
  isdisplayed: "Görünür",
  isNotdisplayed: "Görünür Değil",
  isEmpty: "Boş",
  isNotEmpty: "Boş Değil",
  isSelected: "Seçildi",
  isNotSelected: "Seçilmedi",

  allOptions: "Tüm Seçenekler",
  selectedOptions: "Seçili Seçenekler",
  unselectedOptions: "Seçilmemiş Seçenekler",

  key: "Anahtar",
  value: "Değer",

  EditQuestion: "Soruyu Düzenle",
  QuestionType: "Soru Tipi",
  logicType: "Mantık Tipi",
  embeddedData: "Gömülü Veri",

  Text: "Metin",
  Settings: "Ayarlar",
  Behaviours: "Davranışlar",
  MultipleChoice: "Çoktan Seçmeli",
  ShortText: "Kısa Metin",
  TextGraphic: "Metin / Grafik",
  SurveyQuestion: "Anket Sorusu",
  EmbeddedDataField: "Gömülü Veri Alanı",
  EditQuestionTextDialogTitle: "Soru Metnini Düzenle",
  PlaceholderWriteSomething: "Bir şeyler yazın",
  CancelButton: "İptal",
  notImplementedQuestionBehaviour: " Soru Davranış Ayarları Uygulanmıyor",

  SaveButton: "Kaydet",
  RequiredLabel: "Gerekli",
  ContentTypeLabel: "İçerik Türü",
  TextLabel: "Metin",
  NumberLabel: "Sayı",
  EmailAddressLabel: "E-posta Adresi",
  error: "Hata",
  RequiredQuestionError: "Bu soru zorunludur.",
  textInputTypeError: "Metin girişi geçersiz.",
  integerInputTypeError: "Sayı girişi geçersiz.",
  phoneNumberTypeError:
    "Lütfen 0532 052 52 82 formatında geçerli bir telefon numarası giriniz.",
  numberTypeError: "Lütfen geçerli bir sayı giriniz.",
  emailInputTypeError: "E-posta girişi geçersiz.",
  DisplayLogic: "Görünürlük Seçenekleri",
  DownloadQRCode: "QR Kodunu İndir",
  CopyLink: "Bağlantıyı Kopyala",
  AddElement: "Öğe Ekle",
  AddCondition: "Koşul Ekle",
  EditCondition: "Koşulu Düzenle",

  CloseWindow: "Pencereyi Kapat",
  Link: "Bağlantı",
  QRCode: "QR Kodu",
  CreateQuestion: "Soru Oluştur",
  AddPageBreak: "+ Sayfa Sonu Ekle",
  LogoutButton: "Çıkış Yap",
  AddBlock: "Blok Ekle",
  AddBranch: "Dal Ekle",
  successfullyUpdated: "Başarıyla Güncellendi",
  updateFailed: "Güncelleme Başarısız",

  addBelow: "Aşağı Ekle",

  surveyIsNotPublished: "Anketi dağıtmak için lütfen önce yayınlayınız.",

  builder: "Oluşturucu",
  surveyFlow: "Anket akışı",
  surveyOptions: "Anket seçenekleri",
  design: "Tasarım",

  auth: {
    login: "Giriş yap",
    register: "Kayıt ol",
    emailIsRequired: "Lütfen e-posta adresinizi giriniz",
    passwordIsRequired: "Lütfen şifrenizi giriniz",
    passwordMustBeValid: "Geçerli bir e-posta adresi giriniz",
    alreadyhaveaccount: "Zaten Hesabın Varsa",
    emailOrPassIncorrect: "E-posta veya şifre hatalı, lütfen tekrar deneyin.",
    resetPassword: {
      title: "Şifre Yenile",
      textLabel: "E-posta Adresi veya Telefon numarası",
      buttonLabel: "Aktivasyon linkini gönder",
      invalidInputMessage:
        "Geçerli bir e-posta veya telefon numarası (05329993377) girilmelidir.",
      confirmationMessage:
        "Eğer bilgileriniz geçerliyse aktivasyon için mail/SMS gönderilecek. Mail adresinizi veya telefonunuzu kontrol edebilirsiniz. Sorularınız için: katilimcihavuzu@galataanket.com",
    },
    emailAddress: "E-posta Adresi",
    password: "Şifre",
    continue: "Devam et",
    sendnewpassword: "Send a new password",
    forgotPassword: "Şifrenizi mi unuttunuz?",
    noAccount: "Hesabınız yok mu?",
  },
  designTab: {
    logo: "Logo",
    general: "Genel",
    background: "Arka Plan",
    style: "Stil",
    motion: "Animasyon",
    similars: {
      left: "Sol",
      center: "Orta",
      right: "Sağ",
      top: "Üst",
      bottom: "Alt",
    },
    logoStyles: {
      logoImage: "Logo Görüntüsü",
      change: "Değiştir",
      placement: "Yerleştirme",
      verticalPlacement: "Dikey Yerleştirme",
      maxHeight: "Maksimum Yükseklik",
      maxWidth: "Maksimum Genişlik",
    },
    generalStyles: {
      nextButtonText: "Sonraki Buton Metni",
      previousButtonText: "Önceki Buton Metni",
      progressBar: "İlerleme Çubuğu",
      none: "Hiçbiri",
      withText: "Metinli",
      withoutText: "Metinsiz",
      progressBarPlacement: "İlerleme Çubuğu Yerleşimi",
      progressBarVerticalPlacement: "İlerleme Çubuğu Dikey Yerleşimi",
    },
    styleStyles: {
      primaryColor: "Ana Renk",
      secondaryColor: "İkincil Renk",
      font: "Yazı Tipi",
      questionFontSize: "Soru Yazı Boyutu (px)",
      answersFontSize: "Cevaplar Yazı Boyutu (px)",
    },
    backgroundStyles: {
      backgroundColor: "Arka Plan Rengi",
    },
    motionStyles: {
      animationType: "Animasyon Türü",
      slide: "Kaydır",
      fade: "Solma",
      grow: "Büyüt",
      zoom: "Yakınlaştır",
      collapse: "Daralt",
      slideAnimationDirection: "Kaydırma Animasyonu Yönü",
    },
    previewSide: {
      mySurvey: "Anketim",
      sampleSurvey: "Örnek Anket",
      viewDesktop: "Masaüstü Görünümü",
      viewMobile: "Mobil Görünümü",
      selectSurvey: "Anket Seç",
    },
  },
  participantPaymentRequests: {
    pending: "Beklemede",
    approved: "Onaylandı",
    cancelled: "İptal Edildi",
    rejected: "Reddedildi",
    flagged: "İşaretlendi / Bayraklı",
    participantPaymentRequests: "Katılımcı Ödeme Talepleri",
    requestedStatus: "İstek Durumu",
    participantEmail: "Katılımcı Mail",
    flagle: "İşaretle",
    includeFlagged: "İşaretlileri Dahil Et",
    participantPhone: "Katılımcı Telefonu",
    createdTime: "Oluşturulma Zamanı",
    participantId: "Katılımcı No",
    requestedAmount: "İstenen Miktar",
    requestedMarket: "İstenen Market",
    paymentSent: "Gönderilen Ödeme",
    participantCompletedSurveyDuration: "Tamamlanan Anket Süresi",
    participantCompletedSurvey: "Tamamlanan Anket Sayısı",
    selectedRequest: "Seçilen Talep",
    requestDetail: "İstek Detayı",
    confirmPayment: "Ödemeyi Onayla",
    minute: "dk",
    email: "E-posta",
    phone: "Telefon",
  },

  companyDescription:
    "Galata Anket, dijital anket araçlarını birleştiren bir akademik ve pazar araştırma şirketidir ve müşterilerine veri temelli danışmanlık sunmaktadır.",
  gotoPlatform: "Platforma Git",

  professionalSurveyTeam: "En güvenilir anket deneyimi için profesyonel bir ekip!",
  dedicatedResearchAndAnalysis:
    "Uzman araştırmacılar ve veri analistlerimiz, her müşterinin özel ihtiyaçlarına ve hedeflerine uygun olarak özenle hazırlanan anketleri tasarlamak ve yönetmek için çalışmaktadır. Hedefimiz, online anketler veya odak grupları aracılığıyla doğru, güvenilir ve işlevsel veriler toplamaktır.",
  notOnlyDataCollection: "Bir veriden daha fazlası!",
  clientReportingAndRecommendations:
    "Müşterilerimize ayrıntılı raporlar ve anket sonuçlarının analizini sunmanın yanı sıra, bilgileri kurumlarını geliştirmek ve hedeflerine ulaşmak için nasıl kullanacakları konusunda önerilerde bulunuyoruz.",
  dataDrivenDecisions:
    "Geri Bildirimlerle Büyüme: Müşterilerin Sesiyle Rekabette Öne Çıkın!",
  commitmentToClientSuccess:
    "Müşterilerimizi sağladığımız kaliteli veriler ile rekabetçi piyasada daha görünür kılmak ve kendi müşterilerinin sesini daha net duymalarını sağlamak için çalışıyoruz. Misyonumuza katılarak, siz de müşterilerinizin geri bildirimlerini dinleyebilmenin gücünü keşfedin!",
  messageSent: "Mesajınız gönderildi!",
  messageSendError: "Mesaj gönderilemedi. Lütfen daha sonra tekrar deneyin.",
  welcomeContactPage: "İletişim Sayfamıza Hoş Geldiniz!",
  contactInvitation:
    "Herhangi bir soru veya yorumla bize ulaşmaktan çekinmeyin. Sizden haber almak isteriz!",
  contactUs: "Bize Ulaşın",
  name: "Ad",
  surname: "Soyad",
  mail: "E-posta",
  message: "Mesaj",
  sending: "Gönderiliyor ...",
  sendMessage: "Mesaj Gönder",
  needHelp: "Yardıma mı ihtiyacınız var?",
  home: "Ana Sayfa",
  aboutUs: "Hakkımızda",
  contact: "İletişim",
  platform: "Platform",
  pages: "Sayfalar",
  websiteDisclosure: "Websitesi Aydınlatma Metni",
  cookiePrivacyPolicy: "Çerez ve Gizlilik Politikası",
  dataSubjectApplicationForm: "İlgili Kişi Başvuru Formu",
  ourAddress: "Adresimiz",
  followUs: "Bizi Takip Edin",
  rightsReserved: "Galata Anket. Tüm hakları saklıdır.",
  teamName: "Takım Adı",
  goToDetail: "Detaya Git",
  manageParticipantGroups: {
    manageParticipantGroups: "Katılımcı Gruplarını Yönet",
    email: "E-posta",
    phone: "Telefon",
    searchParticipant: "Katılımcı Ara",
    createParticipantGroup: "Katılımcı Grubu Yarat",
    name: "Grup İsmi",
    memberCount: "Grup Üye Sayısı",
  },
  generic: {
    genericCard: {
      showMore: "Daha Fazla Göster",
      showLess: "Daha Az Göster",
    },
    genericTablePagination: {
      rowsPerPage: "Sayfa başına satır",
      of: "toplam",
      page: "Sayfa",
      noRecords: "Kayıt bulunamadı",
      previousPage: "Önceki sayfa",
      nextPage: "Sonraki sayfa",
    },
  },
};
