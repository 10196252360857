import {Paper, TableContainer, Typography} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {superTeamService} from "src/services/super/team";
import {useParams} from "react-router-dom";

export const CompletedProjects = props => {
  const {t} = useTranslation();
  const {id, teamMemberId} = useParams();
  const [page, setPage] = useState(0);
  const [projects, setProjects] = useState([]);

  const fetchRewardProjects = _page => {
    superTeamService
      .listTeamMemberProjects(id, teamMemberId, _page + 1)
      .then(response => {
        setProjects(response.results);
        setPage(_page);
      });
  };

  useEffect(() => {
    fetchRewardProjects(page);
  }, []);

  return (
    <>
      <Typography
        variant="h5"
        sx={{marginBottom: 4, textAlign: "left", fontWeight: "bold"}}
      >
        {t("events.completedEvents")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("events.surveyName")}</TableCell>
              <TableCell>{t("events.rewardAmount")}</TableCell>
              <TableCell>{t("events.rewardStatus")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(project => (
              <TableRow key={project.id}>
                <TableCell>{project.topic}</TableCell>
                <TableCell>{project.reward}</TableCell>
                <TableCell>
                  {project.reward_status ? t("events.defined") : t("events.undefined")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
