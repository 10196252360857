import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {Container} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {superTeamService} from "src/services/super/team";
import {Typography} from "@mui/material";

export const TeamInvitationDetails = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [joinUrl, setJoinUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const getTeamMemberInvitation = () => {
    setLoading(true);
    const payload = {
      phone: phoneNumber,
    };
    superTeamService
      .getTeamMemberInvitation(payload)
      .then(response => {
        setJoinUrl(response.join_url);
      })
      .catch(_ => setJoinUrl("NOT FOUND"))
      .finally(_ => setLoading(false));
  };

  return (
    <Container maxWidth="lg">
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="Phone"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </Stack>
      <Stack sx={{my: 2}} spacing={12} direction={"row"}>
        <LoadingButton
          fullWidth
          loading={loading}
          onClick={getTeamMemberInvitation}
          sx={{mt: 3}}
          size="large"
          type="submit"
          variant="contained"
        >
          Get
        </LoadingButton>
      </Stack>
      <Stack sx={{my: 2}} spacing={12} direction={"row"}>
        <Typography variant="h6" component="div" sx={{py: 1}}>
          {joinUrl}
        </Typography>
      </Stack>
    </Container>
  );
};
