import {DashboardLayout} from "src/layouts/dashboard/dashboard-layout";
import {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";
import {UnsubscribedUsers} from "src/pages/dashboard/unsubscribed-users";
import {NewSurvey} from "src/pages/dashboard/newsurvey";
import {FindParticipants} from "src/pages/dashboard/findparticipants";
import {SettingCategory} from "src/pages/dashboard/settingcategory";
import {ParticipantRequests} from "src/pages/dashboard/super/participant-requests";
import {ParticipantRequestDetail} from "src/pages/dashboard/super/participant-requests/participant-request-detail";
import ExcellToSurvey from "src/pages/dashboard/excelltosurvey";
import {ParticipantsPaymentRequests} from "src/pages/dashboard/super/participant-payment-request";
import {ParticipantPaymentRequestDetail} from "src/pages/dashboard/super/participant-payment-request/participant-payment-request-detail";
import {ManageParticipantGroups} from "src/pages/dashboard/super/manage-participant-groups";
import {ManageParticipantRewards} from "src/pages/dashboard/super/manage-participant-rewards";
import {InviteParticipant} from "src/pages/dashboard/super/invite-participant";
import {TeamMemberDetails} from "src/pages/admin/teams/team-member-details";
import TeamDetails from "src/pages/admin/teams/team-details";
import {TeamInvitationDetails} from "src/pages/dashboard/super/team-invitation-details";

const SurveyListPage = lazy(() => import("src/pages/admin/surveys"));
const TeamListPage = lazy(() => import("src/pages/admin/teams"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "surveys",
        element: <SurveyListPage />,
      },
      {
        path: "unsubscribed-users",
        element: <UnsubscribedUsers />,
      },
      {
        path: "newsurvey",
        element: <NewSurvey />,
      },
      {
        path: "findparticipants",
        element: <FindParticipants />,
      },
      {
        path: "settingcategory",
        element: <SettingCategory />,
      },
      {
        path: "findpartrequests",
        children: [
          {
            index: true,
            element: <ParticipantRequests />,
          },
          {
            path: ":id",
            element: <ParticipantRequestDetail />,
          },
        ],
      },
      {
        path: "excelltosurvey",
        element: <ExcellToSurvey />,
      },
      {
        path: "participant-payment-request",
        children: [
          {
            index: true,
            element: <ParticipantsPaymentRequests />,
          },
          {
            path: ":id",
            element: <ParticipantPaymentRequestDetail />,
          },
        ],
      },
      {
        path: "manage-participant-groups",
        children: [
          {
            index: true,
            element: <ManageParticipantGroups />,
          },
        ],
      },
      {
        path: "manage-participant-rewards",
        children: [
          {
            index: true,
            element: <ManageParticipantRewards />,
          },
        ],
      },
      {
        path: "team-invitation-details",
        children: [
          {
            index: true,
            element: <TeamInvitationDetails />,
          },
        ],
      },
      {
        path: "invite-participant",
        children: [
          {
            index: true,
            element: <InviteParticipant />,
          },
        ],
      },
      {
        path: "teams",
        children: [
          {
            index: true,
            element: <TeamListPage />,
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: <TeamDetails />,
              },
              {
                path: "members",
                children: [
                  {
                    path: ":teamMemberId",
                    element: <TeamMemberDetails />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
