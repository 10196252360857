import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Divider,
  Stack,
  CircularProgress,
} from "@mui/material";
import {useEffect, useState} from "react";
import {superTeamService} from "src/services/super/team";

export const MemberInformation = ({teamMemberId}) => {
  const [memberData, setMemberData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMemberData = async () => {
    try {
      const response = await superTeamService.getTeamMemberDetails(teamMemberId);
      setMemberData(response);
    } catch (err) {
      setError("Üye bilgileri alınamadı.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemberData();
  }, [teamMemberId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  return (
    <Card sx={{p: 3, borderRadius: 3, boxShadow: 3, maxWidth: 400}}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{width: 70, height: 70, mr: 2}} />
          <Typography variant="h6" fontWeight="bold">
            {memberData.email}
          </Typography>
        </Box>

        <Divider sx={{mb: 2}} />

        <Stack spacing={2}>
          <Box>
            <Typography variant="body2" color="textSecondary">
              Email
            </Typography>
            <Typography variant="body1">{memberData.email}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Telefon
            </Typography>
            <Typography variant="body1">{memberData.phone}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Doğum Tarihi
            </Typography>
            <Typography variant="body1">{memberData.birth_date}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Cinsiyet
            </Typography>
            <Typography variant="body1">
              {memberData.gender === "1" ? "Erkek" : "Kadın"}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Ülke
            </Typography>
            <Typography variant="body1">{memberData.country || "Bilgi Yok"}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Rol
            </Typography>
            <Typography variant="body1">{memberData.role}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Banka Adı
            </Typography>
            <Typography variant="body1">{memberData.bank_name}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              IBAN
            </Typography>
            <Typography variant="body1">{memberData.bank_iban}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Kimlik Numarası
            </Typography>
            <Typography variant="body1">{memberData.bank_identity_number}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              Adres
            </Typography>
            <Typography variant="body1">
              {memberData.bank_province
                ? `${memberData.bank_province}, ${memberData.bank_neighborhood}, ${memberData.bank_street}, ${memberData.bank_building}, ${memberData.bank_postal_code}`
                : memberData.bank_address}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
